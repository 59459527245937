import { Badge, Button, ButtonGroup, Chip, Grid, Grow, IconButton, Tooltip, Typography } from "@mui/material"
import { useStyles } from "./style";
import { ImageComponent } from "../../../../../components/ImageComponent";
import { CreationDate, formatarData, IniciadoEm } from "../../sections";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { formatarParaNomeStatusAdiantamento, formatarParaNomeStatusAdiantamentoList, formatarValorComMoeda } from "../../../../../enums/enumPrestacaoDeContas";
import { DeskCardPersonExpanses } from "../../../../../components/card_person_expanses";
import { useCallback, useEffect, useState } from "react";
import DialogComponent from "../../sections/dialogs-desktop";
import { CurrencyExchangeOutlined, NotificationImportant, QrCode2Outlined } from "@mui/icons-material";
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import prestacaoDeContasRepositorio from "../../../../../repositorios/prestacaodecontas";
import { useCustomSnackbar } from "../../../../../components/snackbar2/NewSnackBar";
import ULoaderVoo from "../../../../../components/uloadervoo";
import { ViewHodometerExpansesComponent } from "../../../../../components/autalii_expanses/viewhodometer_expanses";
import { ViewQrCodeProducts } from "../../../../../components/autalii_expanses/viewqrcode";

export const DetalhesViagemComponent = ({ viagem }) => {
    const classes = useStyles();
    const [despesa, setDespesa] = useState([])
    const [loading, setLoading] = useState(false)
    const [despesas, setDespesas] = useState([])
    const [extras, setExtras] = useState([])
    const [openInfoHodometro, setOpenInfoHodometro] = useState(false);
    const [currentTab, setCurrentTab] = useState(1);
    const [count, setCount] = useState(0)
    const [openQrCodeView, setOpenQrCodeView] = useState(false)

    const { enqueueSnackbar } = useCustomSnackbar();

    useEffect(() => {
        let c = 0;
        extras?.forEach(extra => {
            if (!extra.add) {
                c += 1;
            }
        })
        setCount(c)
    }, [extras])

    const getDespesas = useCallback(async () => {
        setLoading(true);
        try {
            const response = await prestacaoDeContasRepositorio.getDespesas(viagem.id);
            setDespesas(response.despesas);
            setExtras(response.extras);
        } catch (error) {
            enqueueSnackbar('Ocorreu um erro ao buscar despesas.', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    }, [setLoading, viagem.id, setDespesas, setExtras, enqueueSnackbar]);

    const handleClickOpenQrCode = (despesa) => {
        setDespesa(despesa)
        setOpenQrCodeView(true)
    }

    const handleClickOpenHodometro = (despesa) => {
        setDespesa(despesa)
        setOpenInfoHodometro(true)
    }

    const handleChangeTab = useCallback((newValue) => {
        setCurrentTab(newValue);
    }, []);

    useEffect(() => {
        getDespesas()
    }, [getDespesas])

    return (
        <>
            <ULoaderVoo isLoading={loading} />
            {despesa !== null && (
                <>
                    <DialogComponent
                        title={
                            <>
                                Informações do Hodômetro
                            </>
                        }
                        open={openInfoHodometro}
                        handleClose={() => setOpenInfoHodometro(false)}
                        body={
                            <ViewHodometerExpansesComponent despesa={despesa} />
                        }
                        action={
                            <></>
                        }
                        width={"sm"}
                    />
                    <DialogComponent
                        title={
                            <>
                                Informações do QrCode
                            </>
                        }
                        open={openQrCodeView}
                        handleClose={() => setOpenQrCodeView(false)}
                        body={
                            <ViewQrCodeProducts QrCode={despesa?.qrCode} />
                        }
                        action={
                            <></>
                        }
                        width={"xs"}
                    />
                </>
            )}
            <Grid container item xs={12}>
                <Grid item xs={12} container style={{ marginTop: "0px" }}>
                    <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <IniciadoEm datainicio={viagem.datainicio} />
                    </Grid>
                    <Grid item xs={6} style={{ display: "flex", justifyContent: "flex-end", }}>
                        <Grid style={{ marginLeft: "auto" }}>
                            <CreationDate data={viagem.datadecriacao} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "center", justifyContent: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <DeskCardPersonExpanses viagem={viagem} despesas={despesas} />
                    </Grid>
                </Grid>
                {viagem !== null && count !== 0 ? (
                    <Grid item xs={12} style={{ justifyContent: "center", alignContent: "center", textAlign: "center", marginBottom: "0px" }}>
                        <ButtonGroup variant="outlined" color="inherit" style={{ justifyContent: "center", borderColor: "#D6D6D6" }}>
                            {[{ value: 1, label: "Despesas" }, { value: 2, label: "Conciliação" }].map((tab) => (
                                <Button
                                    key={tab.value}
                                    onClick={() => handleChangeTab(tab.value)}
                                    style={{
                                        textTransform: "none", // Mantém a capitalização original do texto
                                        fontWeight: currentTab === tab.value ? 600 : 400, // Destaca o botão ativo
                                        borderColor: "#D6D6D6"
                                    }}
                                >
                                    <Typography variant="h6" style={{ fontSize: "15px", fontWeight: currentTab === tab.value ? 600 : 400 }}>
                                        {tab.label}
                                    </Typography>
                                </Button>
                            ))}
                        </ButtonGroup>
                    </Grid>
                ) : (null)}

                <Grid item xs={12} style={{ justifyContent: "center", padding: "20px", display: "flex", marginBottom: "-20px" }}>
                    {formatarParaNomeStatusAdiantamentoList(viagem.adiantamento)}
                </Grid>

                {currentTab === 1 && (
                    <>
                        {despesas?.map((item, index) => (
                            <>
                                <Grow
                                    in={true}
                                    style={{ transformOrigin: '0 0 0' }}
                                    timeout={(index + 1) * 300} // Atraso na transição para cada linha
                                    key={item.id} // Atribuindo o `key` aqui com `row.id`
                                >
                                    <Grid
                                        item xs={12}
                                        className={classes.card}
                                        style={{ marginTop: "20px" }}
                                        container
                                    >
                                        {item.hodometro ? (
                                            <>
                                                <Grid container item xs={12} style={{ padding: "20px" }}>
                                                    <Grid container item xs={6}>
                                                        <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify" }}>
                                                            <Typography style={{ fontWeight: "600" }}>Hodometro</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                                                        <Grid style={{ marginLeft: "auto" }}>
                                                            <CreationDate data={item.datadecompra} />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={8} style={{ textAlign: "left" }}>
                                                        <Typography style={{ fontWeight: "500", color: "#595959" }}>{item.descricao}</Typography>
                                                    </Grid>
                                                    <Grid item xs={4} style={{ textAlign: "right" }}>
                                                        <Button startIcon={<DirectionsCarIcon />} color="secondary" onClick={() => handleClickOpenHodometro(item)}>
                                                            Ver Informações
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={6} style={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        alignContent: "flex-start",
                                                        alignItems: "flex-end",
                                                        flexDirection: "row",
                                                    }}>
                                                        <Grid style={{ marginLeft: "2px" }}>
                                                            {item.despesa_desc}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6} style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignContent: "flex-end",
                                                        alignItems: "center",
                                                        flexDirection: "row",
                                                    }}>
                                                        {item.qrCode !== null && (
                                                            <Grid>
                                                                <IconButton onClick={() => handleClickOpenQrCode(item)}>
                                                                    <QrCode2Outlined />
                                                                </IconButton>
                                                            </Grid>
                                                        )}
                                                        <Grid style={{}}>
                                                            {item.controladoria ? (
                                                                <>
                                                                    <Chip label="Controladoria" color="warning" style={{ marginTop: "-18px", marginLeft: "5px", color: "white" }} icon={<AdminPanelSettingsIcon />}>
                                                                    </Chip>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {item.aprovado ? (
                                                                        <>
                                                                            {item.data_aprovado ? (
                                                                                <Tooltip title={`Aprovado em ${formatarData(item.data_aprovado, "d MMM 'de' yyyy")} por ${item.usuario_aprovador}`}>
                                                                                    <Chip label="Aprovado" color="primary" />
                                                                                </Tooltip>
                                                                            ) : (
                                                                                <Chip label="Aprovado" color="primary" />
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <Chip label="Pendente" color="secondary" />
                                                                    )}
                                                                </>
                                                            )}
                                                        </Grid>
                                                        <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{item.hodometro.kmtotal} Km - {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.hodometro.valor || 0)}</Typography>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        )
                                            :
                                            (
                                                <>
                                                    <Grid container item xs={10} style={{ padding: "20px" }}>
                                                        <Grid container item xs={6}>
                                                            <Grid container item xs={12} style={{ display: "flex", textAlign: "justify" }}>
                                                                <Typography style={{ fontWeight: "600" }}>Despesa</Typography>
                                                                {(item.limite > 0 && item.total > item.limite) && (
                                                                    <>
                                                                        <Tooltip title={`Essa despesa passou do limite permitido no grupo Alimentação - Jantar de ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.limite)} ela gastou ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.total)}.`}>
                                                                            <Chip
                                                                                label={"Limite excedito"}
                                                                                color="secondary"
                                                                                style={{ fontWeight: "500", marginTop: "-4px", marginLeft: "7px" }}
                                                                            >
                                                                            </Chip>
                                                                        </Tooltip>
                                                                    </>
                                                                )}
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={6} style={{ display: "flex", flexDirection: "column", textAlign: "right", justifyContent: "right" }}>
                                                            <CreationDate data={item.datadecompra} />
                                                        </Grid>
                                                        <Grid item xs={7} style={{ textAlign: "left" }}>
                                                            <Typography style={{ fontWeight: "500", color: "#595959" }}>{item.descricao}</Typography>
                                                        </Grid>
                                                        <Grid item xs={5} style={{ display: "flex", justifyContent: "right", textAlign: "right" }}>
                                                            {item.valor_auditado !== 0 ? (
                                                                <>
                                                                    <Chip label={"Auditado - " + (new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valor_auditado))} color="primary" style={{ marginLeft: "5px", color: "white" }} icon={<CurrencyExchangeOutlined />}>
                                                                    </Chip>
                                                                </>) : (null)}
                                                        </Grid>
                                                        <Grid item xs={6} style={{
                                                            display: "flex",
                                                            justifyContent: "flex-start",
                                                            alignContent: "flex-start",
                                                            alignItems: "flex-start",
                                                            flexDirection: "row",
                                                            marginTop: "auto"
                                                        }}>
                                                            {item.despesa_desc}
                                                        </Grid>

                                                        <Grid item xs={6} style={{
                                                            display: "flex",
                                                            justifyContent: "flex-end",
                                                            alignContent: "flex-end",
                                                            alignItems: "center",
                                                            flexDirection: "row",
                                                            marginTop: "auto"
                                                        }}>
                                                            {item.qrCode !== null && (
                                                                <Grid>
                                                                    <IconButton onClick={() => handleClickOpenQrCode(item)}>
                                                                        <QrCode2Outlined />
                                                                    </IconButton>
                                                                </Grid>
                                                            )}
                                                            <Grid style={{ marginRight: "10px" }}>
                                                                {item.controladoria ? (
                                                                    <>
                                                                        <Chip label="Controladoria" color="warning" style={{ marginTop: "-18px", marginLeft: "5px", color: "white" }} icon={<AdminPanelSettingsIcon />}>
                                                                        </Chip>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {item.aprovado ? (
                                                                            <>
                                                                                {item.data_aprovado ? (
                                                                                    <Tooltip title={`Aprovado em ${formatarData(item.data_aprovado, "d MMM 'de' yyyy")} por ${item.usuario_aprovador}`}>
                                                                                        <Chip label="Aprovado" color="primary" />
                                                                                    </Tooltip>
                                                                                ) : (
                                                                                    <Chip label="Aprovado" color="primary" />
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <Chip label="Pendente" color="secondary" />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Grid>
                                                            <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{formatarValorComMoeda(item.tipo_moeda, item.total)}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container item xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                        <Badge
                                                            color="error"
                                                            variant="standard"
                                                            badgeContent={
                                                                <Tooltip title="Fora da política">
                                                                    <NotificationImportant fontSize="small" style={{ color: "#FFFFFF" }} />
                                                                </Tooltip>
                                                            }
                                                            invisible={item.is_fora_politica !== null && item.is_fora_politica ? false : true}
                                                        // invisible={false}
                                                        >
                                                            <ImageComponent width={100} height={148} src={item.comprovante} alt={"Imagem não encontrada"} />
                                                        </Badge>
                                                    </Grid>
                                                </>
                                            )}
                                    </Grid>
                                </Grow >
                            </>
                        ))}
                    </>
                )}

                {currentTab === 2 && (
                    <>
                        {extras?.map((value) => (
                            <>
                                <Grid
                                    item xs={12}
                                    style={{
                                        padding: "20px",
                                        transition: 'background-color 0.5s ease', // Suaviza a transição do efeito de escurecimento
                                        marginTop: "20px",
                                        cursor: 'default', // Cursor padrão quando não está em hover
                                        backgroundColor: "#FFFFFF",
                                        textAlign: 'center',
                                        borderRadius: "10px",
                                        boxShadow: "0px 0px 8px 1px rgba(0,0,0,0.13)",
                                    }}
                                    container
                                >
                                    <Grid container item xs={12}>
                                        <Grid container item xs={12} style={{ display: "flex", flexDirection: "column", textAlign: "justify", justifyContent: "center" }}>
                                            <Typography style={{ fontWeight: "600" }}>{value.despesa_desc}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ textAlign: "left", marginTop: "8px" }}>
                                        <Typography style={{ fontWeight: "500", color: "#595959" }}>{value.descricao}</Typography>
                                    </Grid>
                                    <Grid item xs={9} style={{ display: "flex", flexDirection: "column", textAlign: "left", justifyContent: "left" }}>
                                        <CreationDate data={value.datacriacao} />
                                    </Grid>
                                    <Grid item xs={3} style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignContent: "flex-end",
                                        alignItems: "flex-end",
                                        flexDirection: "row"
                                    }}>
                                        <Typography style={{ fontWeight: "600", color: "rgba(0, 0, 0, 0.87)" }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value.total)}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                    </>
                )}
                {/* <Grid container item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button variant="contained" color="secondary" style={{ marginRight: "10px" }}>Enviar para</Button>
                    <Button variant="contained" color="primary">Aprovar todas</Button>
                </Grid> */}
            </Grid >
        </>
    )
}