import { Box, Divider, Grid, IconButton, Typography } from "@material-ui/core";
import { EditOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import Enums from "../../../../enums";
import { Chip, Paper } from "@mui/material";

export const HotelTicketMobile = (props) => {
  const classes = useStyles();
  // const cookies = new Cookies();
  // const data = props.hotelTicketData;
  const [hotelData, setHotelData] = useState(props.hotelTicketData);
  const permissaoDashboard = props.permissaoDashboard;

  function contarQtdDias(data1, data2) {
    var dt1 = new Date(data1);
    var dt2 = new Date(data2);

    var diferencaEmMilissegundos = Math.abs(dt2 - dt1);

    var diferencaEmDias = Math.ceil(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));

    return diferencaEmDias;
  }

  function hotelRating(rating) {
    switch (rating) {
      case 1:
        return "Terrível";
      case 2:
        return "Ruim";
      case 3:
        return "Comum";
      case 4:
        return "Bom";
      case 5:
        return "Ótimo";
      default:
        return "Invalid rating";
    }
  }

  useEffect(() => {
    setHotelData(props.hotelTicketData)
  }, [props.hotelTicketData])

  return (
    <>
      <Grid container style={{ padding: '0px' }}>
        {/* <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
          <Typography variant={'h4'}>{hotelData.nome}</Typography>
        </Grid>
        <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
          <Box component="fieldset" borderColor="transparent" style={{ padding: '0px', maxHeight: '60px' }}>
            <Rating name="read-only" value={hotelData.estrelas} readOnly />
          </Box>
        </Grid> */}
        <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
          <img src={hotelData.url_image} alt={"Hotel não contém imagem"} height={'auto'} width={'auto'} style={{ width: "100%", maxHeight: "200px" }} />
        </Grid>

        <Grid item xs={12}>
          <Grid item xs={12} style={{
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            width: '100%',
            height: 'auto',
            flexShrink: 0,
            fill: '#FFF',
            top: "40%",
            borderBottom: 'none',
            zIndex: 2,
            padding: "20px",
            marginTop: "-10px"
          }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6">{hotelData.nome}</Typography>
              <Typography style={{ fontSize: "24px" }}>{hotelData.localizacao.cidade}</Typography>
              <Typography style={{ color: "#5B5B5B" }}>{hotelData.localizacao.endereco}</Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box sx={{ display: 'flex', alignItems: "center", marginLeft: "20px" }}>
            <Typography style={{
              backgroundColor: "#434343",
              padding: "4px 12px 4px 12px",
              width: "fit-content",
              borderRadius: "10px",
              fontWeight: "600",
              color: "white"
            }}>{hotelData.estrelas}</Typography>
            <Typography style={{ fontWeight: "500", fontSize: "18px", marginLeft: "6px" }}>{hotelRating(hotelData.estrelas)}</Typography>
          </Box>
          <Box style={{ textAlign: "center", marginTop: "15px" }}>
            <Chip size="medium" label={Enums.getReservaStatusDescricao(hotelData.reserva.status)} style={{ background: Enums.getReservaStatusCor(hotelData.reserva.status), color: "#ffffff", }} />
          </Box>
          <Divider variant="middle" style={{ marginTop: "15px" }} />
        </Grid>
        <Grid container item xs={12} style={{ padding: "10px" }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '8px' }}>
            <Box>
              <Typography style={{ fontWeight: '600', color: "#5B5B5B", fontSize: "18px" }}>Solicitante:</Typography>
              <Typography variant="body2">{hotelData.reserva.solicitante}</Typography>
            </Box>
            <Box>
              <Typography style={{ fontWeight: '600', color: "#5B5B5B", fontSize: "18px" }}>Criado em:</Typography>
              <Typography variant="body2">{new Date(hotelData.data_criacao).toLocaleString('pt-Br')}</Typography>
            </Box>
          </Box>
        </Grid>

        {/* <Grid item xs={12} style={{ justifyContent: 'justify', display: 'flex', flexWrap: 'wrap' }}>
          <Typography style={{ display: 'flex', alignContent: 'center', marginTop: '2px', marginBottom: '2px' }}>
            <LocalParkingOutlined style={{ color: hotelData.detalhes.tem_estacionamento ? 'green' : 'red', border: hotelData.detalhes.tem_estacionamento ? '1px solid green' : '1px solid red', borderRadius: '5px', marginRight: '5px' }} />{hotelData.detalhes.tem_estacionamento ? "Possui estacionamento" : "Não possui estacionamento"}
          </Typography>
          <Typography style={{ display: 'flex', alignContent: 'center', marginTop: '2px', marginBottom: '2px' }}>
            <LocalLaundryServiceOutlined style={{ color: hotelData.detalhes.tem_lavanderia ? 'green' : 'red', border: hotelData.detalhes.tem_lavanderia ? '1px solid green' : '1px solid red', borderRadius: '5px', marginRight: '5px' }} />{hotelData.detalhes.tem_lavanderia ? "Possui lavanderia" : "Não possui lavanderia"}
          </Typography>
          <Typography style={{ display: 'flex', alignContent: 'center', marginTop: '2px', marginBottom: '2px' }}>
            <PoolOutlined style={{ color: hotelData.detalhes.tem_piscina ? 'green' : 'red', border: hotelData.detalhes.tem_piscina ? '1px solid green' : '1px solid red', borderRadius: '5px', marginRight: '5px' }} />{hotelData.detalhes.tem_piscina ? "Possui piscina" : "Não possui piscina"}
          </Typography>
          <Typography style={{ display: 'flex', alignContent: 'center', marginTop: '2px', marginBottom: '2px' }}>
            <RestaurantOutlined style={{ color: hotelData.detalhes.tem_restaurante ? 'green' : 'red', border: hotelData.detalhes.tem_restaurante ? '1px solid green' : '1px solid red', borderRadius: '5px', marginRight: '5px' }} />{hotelData.detalhes.tem_restaurante ? "Possui restaurante" : "Não possui restaurante"}
          </Typography>
        </Grid> */}
        {/* <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex' }}>
          <Typography variant={'h5'}>{hotelData.nome_rede}</Typography>
        </Grid>
        <Grid item xs={12} style={{ justifyContent: 'center' }}>
          <Typography variant={'body2'}>{`${hotelData.localizacao.endereco},${hotelData.localizacao.cidade} - ${hotelData.localizacao.pais}`}</Typography>
          <Grid container>
            <Grid item xs={6} style={{ justifyContent: 'center', display: 'flex' }}><Typography><b>Solicitante:</b></Typography></Grid>
            <Grid item xs={6} style={{ justifyContent: 'center', display: 'flex' }}>
              <Typography><b>Criando em:</b></Typography></Grid>
            <Grid item xs={6} style={{ justifyContent: 'center', display: 'flex' }}><Typography>{hotelData.reserva.solicitante}</Typography></Grid>
            <Grid item xs={6} style={{ justifyContent: 'center', display: 'flex' }}><Typography>{new Date(hotelData.data_criacao).toLocaleString('pt-Br')}</Typography></Grid>
          </Grid>
        </Grid> */}
        {/* <Grid item xs={12} style={{ height: "100px" }} >
          <MapContainer center={[hotelData.localizacao.latitude, hotelData.localizacao.longitude]} zoom={13}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[hotelData.localizacao.latitude, hotelData.localizacao.longitude]} icon={customIcon}>
            </Marker>
          </MapContainer>
        </Grid> */}
        <Grid container item xs={12} style={{ padding: "20px" }}>
          <Grid item xs={12} style={{ display: 'flex', marginTop: "-17px" }}>
            <Typography variant={'h6'}><b>INFORMAÇÕES DA RESERVA</b></Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "17px" }}>
            <Typography className={classes.typoInfoReserva}><p>Telefone </p><p>{props.formatarTelefone(hotelData.telefone)}</p></Typography>
            <Typography className={classes.typoInfoReserva}><p>Email </p><p>{hotelData.email}</p></Typography>
            <Typography className={classes.typoInfoReserva}><p>Check-in </p><p>{new Date(hotelData.quartos[0].data_entrada).toLocaleDateString('pt-Br')}</p></Typography>
            <Typography className={classes.typoInfoReserva}><p>Check-out </p><p>{new Date(hotelData.quartos[0].data_saida).toLocaleDateString('pt-Br')}</p></Typography>
            <Typography className={classes.typoInfoReserva}><p>Nº de Quartos</p><p>{hotelData.quartos.length}</p></Typography>
            <Divider variant="middle" style={{ marginTop: "18px" }} />
          </Grid>
          <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex', marginTop: "18px" }}>
            <Typography variant={'h6'}><b>VALORES</b></Typography>
          </Grid>
          <Grid item xs={12} >
            <Typography className={classes.typoInfoReserva}><p>Total</p><p><b>{hotelData.total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</b></p></Typography>
          </Grid>
          <Grid item xs={12} style={{ justifyContent: 'center', display: 'flex', marginTop: "15px" }}>
            <Typography variant={'h6'}><b>QUARTOS</b></Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "15px" }}>
            <Paper elevation={3} style={{ padding: '20px', borderRadius: "10px" }}>
              <Grid container>
                {hotelData.quartos.map(item => (
                  <>
                    <Grid item xs={12}>
                      <Typography><b>{`${item.descricao_tipo_acomodacao} (${item.descricao_tipo_quarto})`}</b></Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.typoInfoReserva}><p>{item.servicos_quartos}</p></Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.typoInfoReserva}><p>Check-in</p><p>{new Date(item.data_entrada).toLocaleDateString('pt-Br')}</p></Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.typoInfoReserva}><p>Check-out</p><p>{new Date(item.data_saida).toLocaleDateString('pt-Br')}</p></Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.typoInfoReserva}><p>Valor diária:</p><p>{item.valor_diaria.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p></Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.typoInfoReserva}><p>Nº de diárias:</p><p>{contarQtdDias(item.data_entrada, item.data_saida)}</p></Typography>
                    </Grid>
                    <Grid item container xs={12}>
                      <Grid item xs={12}>
                        <Typography ><b>Hospede(s)</b></Typography>
                      </Grid>
                      {item.hospedes.map(pax => (
                        <Grid item xs={12}>
                          <Typography>{`${pax.nome} ${pax.sobrenome}`}  - {`${pax.nome_centrocusto} (${pax.codigo})`}
                            {permissaoDashboard.usuarioLinestur ? (
                              <IconButton onClick={() => props.openChangeCCButton(pax.idpessoas)}>
                                <EditOutlined />
                              </IconButton>
                            ) : null}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                    <Grid item container xs={12}>
                      <Grid item xs={12}>
                        <Typography><b>Regra(s)</b></Typography>
                      </Grid>
                      {item.regras.map(rule => (
                        <>
                          <Grid item xs={12}>
                            <Typography>{rule.deadline_descricao}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography>Data limite: {new Date(rule.deadline_data_limite).toLocaleDateString('pt-Br')}</Typography>
                          </Grid>
                          <Divider variant={'middle'} />
                        </>
                      ))}
                    </Grid>
                  </>
                ))}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
