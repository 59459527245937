import { Accordion, AccordionDetails, AccordionSummary, AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Menu, MenuItem, Paper, Slide, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Toolbar, Tooltip, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { AddCircleOutline, ArrowDownwardOutlined, ArrowUpwardOutlined, Assignment, Close, CloseOutlined, ExpandMoreOutlined, FlightLandOutlined, FlightTakeoffOutlined, InfoOutlined, SignalCellularNull, SwapVertOutlined, TuneOutlined, WorkOffOutlined, WorkOutlineOutlined, YoutubeSearchedForOutlined, } from "@material-ui/icons";
import { Pagination } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookie from "universal-cookie";
import { reservar, tarifarAereo } from "../../../../LinkAereo";
import LinesTextField from "../../../../components/LinesTextField";
import UCheckbox from "../../../../components/UCheckbox";
import Snackbar from "../../../../components/snackbar";
import UFloatButton from "../../../../components/ufloatbutton";
import ULoaderVoo from "../../../../components/uloadervoo";
import USearchField from "../../../../components/usearchfield";
import USelect from "../../../../components/uselect";
import EnumCompany from "../../../../enums/enumFlightCompany";
import LogoAzul from "../../../../images/azul.png";
import LogoGol from "../../../../images/gol.png";
import LogoItapemirim from "../../../../images/itapemirim.png";
import LogoLatam from "../../../../images/latam.jpg";
import LogoMap from "../../../../images/map.png";
import LogoVoePass from "../../../../images/voepass.png";
import PoliticaViagemRepositorio from "../../../../repositorios/politicaViagem";
import usuarioRepositorio from "../../../../repositorios/usuario";
import CountDownTimer from "../../../../utils/CountDownTimer/index.jsx";
import DialogWarning from "../../../../utils/dialogWarning/index.jsx";
import EnviarEmail from "../cotar";
import Reservar from "../reservar";
import { useStyles } from "./styles";
import { IconsSource } from "../../../../images/Icons/index.js";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FlightIcon from '@material-ui/icons/Flight';
import { Badge } from "@mui/material";
import { AutoAwesome } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box p={3}>{children}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
};

export const ListaAereoReservaFacil = (props) => {
    const classes = useStyles();
    const cookies = new Cookie();
    const theme = useTheme();
    const tableRef = React.createRef();
    const todosVoos = useSelector((state) => state.linkAereo.voosIda);
    const [voos, setVoos] = useState([]);
    const [numberPerPage, setNumberPerPage] = useState([]);
    const [numberPerPageAlterado, setNumberPerPageAlterado] = useState(false);
    const [countPage, setCountPage] = useState([]);
    const permissaoDashboard = useSelector((state) => state.permissao.permissaoDashboard);
    const variadosReduxTipoPesquisa = useSelector((state) => state.variados.aereosSearch);
    const pesquisado = useSelector((state) => state.variados.dtoPesquisaAereo);
    const [trechosSelecionado, setTrechosSelecionado] = useState([]);
    const [rateTokens, setRateTokens] = useState([]);
    const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [value, setValue] = React.useState(0);
    const [bagagens, setBagagens] = useState([]);
    const [bagagem, setBagagem] = useState([]);
    const [modalPassageirosOpen, setModalPassageirosOpen] = useState(false);
    const [modalPolitica, setModalPolitica] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [justificativa, setJustificativa] = useState("");
    const [valorTarifado, setValorTarifado] = useState(0);
    const [passageiros, setPassageiros] = useState([]);
    const [solicitantes, setSolicitantes] = useState([]);
    const [solicitante, setSolicitante] = useState({});
    const [showButtonReservar, setShowButtonReservar] = useState(true);
    const [validacao, setValidacao] = useState({ visible: false });
    const [mensagemValidacao, setMensagemValidacao] = useState("");
    const [snackbarSucess, setSnackbarSucess] = useState({ visible: false });
    const [antecedencia, setAntecedencia] = useState(false);
    const [isLoadingTarifar, setIsLoadingTarifar] = useState(false);
    const [modalCotar, setModalCotar] = useState(false);
    const [servicos, setServicos] = useState(false);
    const [listaCotacao, setListaCotacao] = useState([]);
    const [order, setOrder] = useState("asc");
    const [orderBy, setOrderBy] = useState("sembagagem");
    const [openDialogWarning, setOpenDialogWarning] = useState(false);
    const [searchNvoo, setSearchNvoo] = useState(0);
    const [openFilter, setOpenFilter] = useState(false)
    const [openLegInfo, setOpenLegInfo] = useState(false);
    const [legInfo, setLegInfo] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    let subList;
    var teste = [];
    const [dtoOrder, setDtoOrder] = useState({
        saida: null,
        chegada: null,
        voo: null,
        semBagagem: null,
        comBagagem: null,
    });
    const [headers, setHeaders] = useState([
        { label: "Voo", icon: null },
        { label: "Saída", icon: null },
        { label: "Chegada", icon: null },
        { label: "Sem Bagagem", icon: null },
        { label: "Com Bagagem", icon: null },
    ]);

    const headersInicial = [
        { label: "Voo", icon: null },
        { label: "Saída", icon: null },
        { label: "Chegada", icon: null },
        { label: "Sem Bagagem", icon: null },
        { label: "Com Bagagem", icon: null },
    ];
    const getLogoCompanhiaAerea = {
        JJ: LogoLatam,
        LA: LogoLatam,
        G3: LogoGol,
        "2Z": LogoVoePass,
        "7M": LogoMap,
        AD: LogoAzul,
        "8I": LogoItapemirim,
    };
    const actionsServicos = [
        {
            icon: <Assignment />,
            name: "Enviar cotação",
            action: () => {
                setServicos(false);
                setModalCotar(true);
            },
        },
        {
            icon: <YoutubeSearchedForOutlined />,
            name: "Nova pesquisa",
            action: () => {
                setServicos(false);
                handleClickPesquisaAereoVoltar();
            },
        },
        {
            icon: <TuneOutlined />,
            name: "Filtrar",
            action: () => {
                setOpenFilter(true)
            }
        }
    ];

    const handleClickInfoLeg = (leg) => {
        setLegInfo(leg);
        setOpenLegInfo(true);
    };

    const getSemBagagem = (lista) => {
        var index = 0;
        lista.map((item) => {
            if (item.fareProfile.baggage == null || item.fareProfile.baggage == undefined) { index += 1; }
        });
        let tem = false;
        if (index == 0) { tem = lista.filter((item) => !item.fareProfile.baggage.isIncluded); }
        var teste = tem ? tem.reduce((acum, atual) => {
            return atual.fareGroup.priceWithTax < acum || acum <= 0 ? atual.fareGroup.priceWithTax : acum;
        }, 0) : "Não possui";
        return teste === null ? "Não possui" : teste;
    };

    const getComBagagem = (lista) => {
        let tem = lista.filter((item) => item.fareProfile.baggage.quantity >= 1);
        return tem ? tem.reduce((acum = 0, atual) => {
            return atual.fareGroup.priceWithTax < acum || acum <= 0 ? atual.fareGroup.priceWithTax : acum;
        }, 0) : "Não possui";
    };

    const montarAssunto = () => {
        let assunto = "";
        switch (props.aereos.tipoCategoria) {
            case "1":
            case "3":
                trechosSelecionado.map((item, index) => {
                    assunto += item[bagagem[index]].departure + " → " + item[bagagem[index]].arrival + " <br /> ";
                });
                break;
            case "2":
                assunto = trechosSelecionado[0][bagagem[0]].departure + " ⇄ " + trechosSelecionado[0][bagagem[0]].arrival;
                break;
        }
        return assunto;
    };

    const montarDtoReservaTarifa = (politica) => {
        let assunto = montarAssunto();
        let dtoTarifarAereo = {
            idapi: trechosSelecionado[0][0].idapi,
            politica: politica,
            justificativa,
            idsolicitante: solicitante.value || 0,
            assunto: assunto,
            tipoAereo: Number(props.aereos.tipoCategoria),
            sourceCode: trechosSelecionado[0][0].legs[0].managedBy.iata,
            rateToken: rateTokens,
            adultos: props.aereos.adultos,
            criancas: props.aereos.criancas,
            bebes: props.aereos.bebes,
            sessionId: cookies.get("sessionId"),
            baseTarifaria: [...trechosSelecionado.map((item, index) => item[bagagem[index]]?.baseTarifaria),],
            fidelidade: { ...trechosSelecionado[0][0].fidelidade, },
            Trechos: trechosSelecionado.map((item) => {
                return {
                    origem: item[0].departure,
                    destino: item[0].arrival,
                    dataida: item[0].departureDate,
                    numeroVoo: item[0].legs.map((i) => i.flightNumber),
                    dataspartida: item[0].legs.map((i) => i.departureDate),
                };
            }),
            passageiros: passageiros.map((p) => {
                return {
                    porcentagem: p.porcentagem,
                    idcentroCusto: p.idcentrocusto,
                    idpassageiro: p.idpassageiro,
                    faixaEtaria: p.faixaEtaria.type,
                    nome: p.nome,
                    sobrenome: p.sobrenome,
                    dataNascimento: p.dataNascimento || null,
                    cpf: p.cpf || 0,
                    email: p.email,
                    genero: p.genero,
                };
            }),
        };
        return dtoTarifarAereo;
    };

    const ordenarTabela = (campo, index) => {
        let heade = headersInicial;
        setOrderBy(campo);
        setOrder(order === "asc" ? "desc" : "asc");
        heade[index] = {
            ...heade[index],
            icon:
                order === "asc" ? (<ArrowDownwardOutlined className={classes.iconeOrdem} />) : (<ArrowUpwardOutlined className={classes.iconeOrdem} />),
        };
        setHeaders(heade);
    };

    const ordenarLista = (i, campo, ordem) => {
        const listaVoos = [...todosVoos[i]];
        listaVoos.sort((a, b) => {
            const { fareGroup: fareGroupA, legs: legsA } = a[0];
            const { fareGroup: fareGroupB, legs: legsB } = b[0];
            const aData = campo === "sembagagem" || campo === "combagagem" ? fareGroupA.priceWithTax : new Date(legsA[0][campo]);
            const bData = campo === "sembagagem" || campo === "combagagem" ? fareGroupB.priceWithTax : new Date(legsB[0][campo]);
            return ordem === "asc" ? aData - bData : bData - aData;
        });

        const filtroVoos = listaVoos.filter((element) => element.some((item) => item.baseTarifaria.some((a) => a.numeroDoVoo === searchNvoo)));

        if (filtroVoos.length >= 0 && searchNvoo) {
            subList = filtroVoos;
        } else {
            subList = listaVoos;
        }

        const numFatiamentos = Math.floor((subList.length - 1) / 25) + 1;

        countPage[i] = numFatiamentos;

        return subList.slice(numberPerPage[i] - 25, numberPerPage[i]);
    };

    const handleClickPesquisaAereoVoltar = () => {
        props.history.push("/consultaAereo");
    };

    const reservarAereo = async () => {
        try {
            setShowButtonReservar(false);
            setIsLoading(true);

            let dtoTarifarAereo = montarDtoReservaTarifa(!antecedencia);

            const response = await reservar(dtoTarifarAereo);

            if (response.data.variant != "error") {
                setMensagemValidacao(response.data.message);
                setSnackbarSucess({ visible: true });
                setModalPassageirosOpen(false);
                setTimeout(() => {
                    props.history.push("/listaReservas");
                }, 1500);
                return;
            }
            setShowButtonReservar(true);
            setMensagemValidacao(response.data.message);
            setValidacao({ visible: true });
        } catch (e) {
            setShowButtonReservar(true);
            setMensagemValidacao("Ocorreu um erro ao executar essa operação!");
            setValidacao({ visible: true });
        } finally {
            setIsLoading(false);
        }
    };

    const onClickConfirmarPolitica = async () => {
        setModalPolitica(false);
        reservarAereo();
    };

    const onClickOpenReservarModal = async () => {
        setIsLoadingTarifar(true);
        try {
            if (trechosSelecionado.length < voos) {
                setMensagemValidacao("Favor selecionar voos para todos os trechos!");
                setValidacao({ visible: true });
                return;
            }

            if (voos.length > 0 && voos.length === trechosSelecionado.length) {
                let i = trechosSelecionado[0].length;

                let temCompaniaDiferente =
                    variadosReduxTipoPesquisa.tipoPesquisaAct == "nacional" ? trechosSelecionado.filter(
                        (item) =>
                            trechosSelecionado[0][0].legs[0].managedBy.iata !==
                            item[0].legs[0].managedBy.iata
                    ) : [];

                if (temCompaniaDiferente.length > 0) {
                    setMensagemValidacao("Por favor selecione voos da mesma companhia aérea!");
                    setValidacao({ visible: true });
                }
            }

            let datas = [];
            trechosSelecionado.map((item, index) => {
                datas.push(item[bagagem[index]].departureDate);
            });

            let dtoValidarAntecedencia = {
                datas,
                sessionId: cookies.get("sessionId"),
                datacriacao: new Date(),
            };

            if (!trechosSelecionado.length) {
                setIsLoadingTarifar(false);
                setMensagemValidacao("Sem trecho(s) selecionado.");
                setValidacao({ visible: true });
                return;
            }

            const antecedenciaResponse = await PoliticaViagemRepositorio.validarAntecedencia(dtoValidarAntecedencia);

            setAntecedencia(antecedenciaResponse.data.antecedencia);

            let trechos = pesquisado.dtoPesquisaAereo.Trechos;
            let ratetoke = trechosSelecionado.map((item, index) => item[bagagem[index]].rateToken);

            let dtoTarifarAereo = {
                rateToken: ratetoke,
                idapi: trechosSelecionado[0][0].idpai,
                baseTarifaria: [...trechosSelecionado.map((item, index) => item[bagagem[index]]?.baseTarifaria),],
                trechos,
            };

            setIsLoadingTarifar(true);
            const responsePricing = await tarifarAereo(dtoTarifarAereo);
            if (!responsePricing.data.priceGroup) {
                let tem = responsePricing.data.error.code === "01-000-1528" ? true : false;
                if (tem) {
                    setIsLoadingTarifar(false);
                    setMensagemValidacao("Não é possivel reservar voos com tipos de bagagem diferentes!");
                    setValidacao({ visible: true });
                    return;
                }
                setIsLoadingTarifar(false);
                setMensagemValidacao("Ocorreu um erro ao tarifar!");
                setValidacao({ visible: true });
                return;
            }

            setValorTarifado(responsePricing.data.priceGroup.fareGroup.priceWithTax);
            setRateTokens(responsePricing.data.priceGroup.segments.map((item) => item.rateToken));
            setIsLoadingTarifar(false);
            setModalPassageirosOpen(true);
        } catch (e) {
            setIsLoadingTarifar(false);
            setMensagemValidacao("Ocorreu um erro ao executar essa operação!");
            setValidacao({ visible: true });
        } finally {
            setIsLoadingTarifar(false);
        }
    };

    const onClickReservar = async () => {
        setShowButtonReservar(false);
        const NaoTemTodosPassageirosPreenchidos = passageiros.find((passageiro) => !passageiro.idpassageiro);
        if (NaoTemTodosPassageirosPreenchidos) {
            setMensagemValidacao("Preencha todos os passageiros para reservar");
            setValidacao({ visible: true });
            setShowButtonReservar(true);
            return;
        }

        if (!antecedencia) {
            setModalPolitica(true);
            setShowButtonReservar(true);
            return;
        }
        reservarAereo();
    };

    const handleClickSelecionarTrecho = (item, index) => {
        for (var i = 0; i < value; i++) {
            if (trechosSelecionado[i] == undefined) return;
        }

        let trechos = [...trechosSelecionado];
        trechos[value] = item;

        setTrechosSelecionado(trechos);

        let listaBagagens = item.map((segment, index) => {
            return {
                preco: segment.fareGroup.priceWithTax,
                quantidadeBagagem: segment.fareProfile.baggage.quantity,
                value: index,
                label: segment.fareProfile.baggage.isIncluded
                    ? `Com bagagem - (${(segment.fareProfile.fareFamily == "null"
                        ? "Sem nome"
                        : segment.fareProfile.fareFamily) || segment.fareType
                    })`
                    : `Sem bagagem - (${(segment.fareProfile.fareFamily == "null"
                        ? "Sem nome"
                        : segment.fareProfile.fareFamily) || segment.fareType
                    })`,
            };
        });

        let bagagemSelecionada = [...bagagem];

        bagagemSelecionada[index] = 0;
        setBagagem(bagagemSelecionada);
        let bagagemAtual = [...bagagens];
        bagagemAtual[value] = listaBagagens;
        setBagagens(bagagemAtual);
        if (trechos.length < voos.length) {
            setValue(value + 1);
            return;
        }
        setValue(voos.length);
    };

    const handleClickAlterarTrecho = (index) => {
        setValue(index);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeCheckBox = (event, element, indexVoo) => {
        let lista = [];
        switch (event.target.checked) {
            case true:
                lista = [...listaCotacao];
                lista[indexVoo] = !lista[indexVoo] ? [] : lista[indexVoo];
                lista[indexVoo].push(element);
                setListaCotacao(lista);
                element[0].checkbox = true;
                break;
            case false:
                lista = [...listaCotacao];
                element[0].checkbox = false;
                lista[indexVoo] = lista[indexVoo].filter(
                    (e) => e[0].rateToken !== element[0].rateToken
                );
                setListaCotacao(lista);
                break;
            default:
                break;
        }
    };

    const functTeste = (item, index) => {
        if (listaCotacao[0]) {
            if (listaCotacao[0][0]) {
                if (listaCotacao[0][0][index].rateToken == item[0].rateToken) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return false;
        }
    };

    const handleChangePagination = (value, i) => {
        let temp = numberPerPage;
        temp[i] = value * 25;
        setNumberPerPage(temp);
        setNumberPerPageAlterado(!numberPerPageAlterado);
        tableRef.current.scrollIntoView();
    };
    function compareFlightNumber(a, b) {
        if (a[0].legs[0].flightNumber < b[0].legs[0].flightNumber) {
            return -1;
        }
        if (a[0].legs[0].flightNumber > b[0].legs[0].flightNumber) {
            return 1;
        }
        return 0;
    }
    function compareFlightNumberDresc(a, b) {
        if (a[0].legs[0].flightNumber > b[0].legs[0].flightNumber) {
            return -1;
        }
        if (a[0].legs[0].flightNumber < b[0].legs[0].flightNumber) {
            return 1;
        }
        return 0;
    }

    const funcaoQueOThalyssonVaiMudarONome = () => {
        if (numberPerPage.length > 0) {
            setIsLoading(true);
            let temp = [];
            let checked = false;
            let newArray = [];
            todosVoos.map((item, index) => {
                let teste = item;
                if (dtoOrder.voo != null) {
                    if (dtoOrder.voo) teste = item.sort(compareFlightNumber);
                    else teste = item.sort(compareFlightNumberDresc);
                }
                temp.push([]);
                temp[index] = teste.slice(
                    numberPerPage[index] - 25,
                    numberPerPage[index]
                );
            });
            teste = temp;
            if (teste) {
                for (let index = 0; index < teste[0].length; index++) {
                    newArray.push(teste[0][index][0]);
                }
            }
            setVoos(temp);
            setIsLoading(false);
        }
    };

    const handleSearchNVoo = () => {
        setOpenFilter(false)
        setSearchNvoo((prevSearchNvoo) => {
            const inputValue = document.getElementById("inputSearchNvoo").value;
            return inputValue;
        });
    }

    useEffect(() => {
        (async () => {
            let listaBagagem = [];
            voos.map((item) => {
                listaBagagem.push(0);
            });
            setBagagem(listaBagagem);
            let listaUsuarios = await usuarioRepositorio.getSearchField();
            setSolicitantes(listaUsuarios);
        })();
    }, []);

    useEffect(() => {
        funcaoQueOThalyssonVaiMudarONome();
    }, [numberPerPageAlterado]);

    useEffect(() => {
        let countTemp = [];
        let perPage = [];
        todosVoos.map((item, index) => {
            let t = Math.floor(item.length / 25);
            let r = item.length % 25;
            countTemp.push(r > 0 ? t + 1 : t);
            perPage.push(25);
        });
        setNumberPerPage(perPage);
        setNumberPerPageAlterado(!numberPerPageAlterado);
        setCountPage(countTemp);
    }, [todosVoos]);

    const ordenarListaNvoo = (i, campo, ordem) => {
        let listaTrechos = todosVoos;
        let listaVoos = listaTrechos[i].flatMap((element) =>
            element.filter((item) => {
                item.baseTarifaria.some((a) => a.numeroDoVoo === searchNvoo);
            })
        );

        listaVoos.sort((a, b) => {
            if (ordem === "asc") {
                if (campo === "sembagagem" || campo === "combagagem") {
                    return a[0].fareGroup.priceWithTax - b[0].fareGroup.priceWithTax;
                }
                return new Date(a[0].legs[0][campo]) - new Date(b[0].legs[0][campo]);
            }
            if (campo === "sembagagem" || campo === "combagagem") {
                return b[0].fareGroup.priceWithTax - a[0].fareGroup.priceWithTax;
            }
            return new Date(b[0].legs[0][campo]) - new Date(a[0].legs[0][campo]);
        });

        listaTrechos[i] = [...listaVoos];
        return listaVoos.slice(numberPerPage[i] - 25, numberPerPage[i]);
    };

    useEffect(() => {
        setSearchNvoo(null)
    }, [value])

    return (
        <Box className={classes.root}>
            <Dialog
                open={openLegInfo}
                onClose={() => setOpenLegInfo(false)}
            >
                <DialogTitle>Atenção</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography style={{ padding: '10px' }}>
                            {legInfo && (
                                <Typography style={{ padding: '10px' }}>
                                    O voo {legInfo.flightNumber} está sendo operado pela cia {EnumCompany.getLogoCompanhiaAereaEnum(legInfo.operatedBy.iata).nome} ({legInfo.operatedBy.iata}).
                                </Typography>
                            )}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
            <Snackbar
                {...{
                    variant: "error",
                    message: mensagemValidacao,
                    visible: validacao.visible,
                    setSnackbar: setValidacao,
                }}
            />
            <Snackbar
                {...{
                    variant: "success",
                    message: mensagemValidacao,
                    visible: snackbarSucess.visible,
                    setSnackbar: setValidacao,
                }}
            />

            <ULoaderVoo
                {...{ isLoading: isLoadingTarifar }}
                titulo="Tarifando Voo..."
            />

            <DialogWarning
                action={handleClickPesquisaAereoVoltar}
                {...{
                    openDialogWarning,
                    setOpenDialogWarning,
                }}
            />
            <Dialog
                open={modalPassageirosOpen}
                maxWidth="md"
                fullWidth={true}
                fullScreen={fullscreen ? true : false}
                onClose={() => setModalPassageirosOpen(false)}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography>Passageiros</Typography>
                        <IconButton onClick={() => setModalPassageirosOpen(false)}>
                            <Close />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent style={{ padding: fullscreen ? 0 : "auto" }}>
                    <Dialog
                        open={modalPolitica}
                        maxWidth="sm"
                        fullWidth={true}
                        fullScreen={fullscreen ? true : false}
                        onClose={() => setModalPolitica(false)}
                    >
                        <DialogTitle className={classes.dialogTitle}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Typography>Justificativa</Typography>
                                <IconButton onClick={() => setModalPolitica(false)}>
                                    <Close />
                                </IconButton>
                            </div>
                        </DialogTitle>
                        <DialogContent>
                            <Grid container xs={12}>
                                <Grid item xs={12}>
                                    <LinesTextField
                                        multiline
                                        variant="outlined"
                                        margin="dense"
                                        rows={6}
                                        placeholder="Descreva a justificativa."
                                        fullWidth
                                        value={justificativa}
                                        onChange={(e) => setJustificativa(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions id="action1" style={{ background: "#d1d1d1" }}>
                            <Button
                                onClick={onClickConfirmarPolitica}
                                className={classes.button}
                            >
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <ULoaderVoo
                        {...{ isLoading: isLoading }}
                        titulo="Reservando Voo..."
                    />
                    <Grid container spacing={2} style={{ margin: 0 }} item xs={12}>
                        {permissaoDashboard.usuarioLinestur ? (
                            <Grid
                                item
                                xs={fullscreen ? 12 : 4}
                                style={{
                                    padding: !fullscreen
                                        ? theme.spacing(0, 1)
                                        : theme.spacing(0, 3),
                                }}
                            >
                                <USearchField
                                    itens={solicitantes}
                                    value={solicitante}
                                    setValue={setSolicitante}
                                    label="Solicitante"
                                />
                            </Grid>
                        ) : null}
                        <Grid item xs={12}>
                            <Reservar
                                setPassageiros={setPassageiros}
                                {...{ props: props, passageiros: passageiros }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions id="action2" className={classes.dialogActions}>
                    <Typography>
                        Total: R${Number(valorTarifado).toFormatDefault()}
                    </Typography>
                    {showButtonReservar ? (
                        <Badge
                            badgeContent={
                                <AutoAwesome style={{ color: "#FFFFFF" }} />
                            }
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            overlap="circular"
                        >
                            <Button
                                disabled={isLoading}
                                onClick={(e) => {
                                    onClickReservar();
                                }}
                                className={classes.button}
                            >
                                {/* Reservar */}
                                Garantir Tarifa
                            </Button>
                        </Badge>
                    ) : null}
                </DialogActions>
            </Dialog>
            <Dialog
                open={modalCotar}
                maxWidth="lg"
                TransitionComponent={Transition}
                fullWidth={true}
                fullScreen={true}
                onClose={() => setModalCotar(false)}
            >
                <AppBar position="relative" style={{ background: "#616161" }}>
                    <Toolbar
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography style={{ color: "white" }}>
                            Enviar cotação por e-mail
                        </Typography>
                        <IconButton onClick={() => setModalCotar(false)}>
                            <Close style={{ color: "white" }} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <EnviarEmail
                        {...{ listaCotacao, voos: voos, setModalCotar: setModalCotar }}
                    />
                </DialogContent>
            </Dialog>
            <Dialog
                maxWidth="md"
                open={openFilter}
                fullScreen={fullscreen}
                onClose={() => setOpenFilter(false)}
            >
                <DialogTitle className={classes.dialogTitle2}>
                    <Typography
                        style={{
                            color: "white",
                            fontSize: "16pt",
                            fontWeight: "bold",
                        }}
                    >
                        Filtros
                    </Typography>
                    {fullscreen ? (
                        <IconButton
                            onClick={() => setOpenFilter(false)}
                            style={{ marginLeft: "15px" }}
                        >
                            <CloseOutlined
                                fontSize="large"
                                style={{ color: "white" }}
                            />
                        </IconButton>
                    ) : null}
                </DialogTitle>
                <DialogContent>
                    <LinesTextField
                        variant="outlined"
                        margin="dense"
                        placeholder="N° Voo"
                        id="inputSearchNvoo"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleSearchNVoo()} style={{
                        background: '#c62828',
                        color: 'white'
                    }}>
                        Continuar
                    </Button>
                </DialogActions>

            </Dialog>
            <AppBar
                position="relative"
                style={{
                    display: "flex",
                    flexDirection: "row",
                    background: "#d1d1d1",
                    color: "black",
                    top: "auto",
                    left: "auto",
                    right: "auto",
                }}
            >
                {fullscreen ? null : (
                    <>
                        <Button
                            size="small"
                            style={{ margin: "10px" }}
                            variant="outlined"
                            className={classes.button}
                            startIcon={<YoutubeSearchedForOutlined />}
                            onClick={() => handleClickPesquisaAereoVoltar()}
                        >
                            Pesquisar
                        </Button>
                        <CountDownTimer
                            setResponseTimer={setOpenDialogWarning}
                            timeInSeconds={1800}
                            colorTypography={"#c62828"}
                        />
                    </>
                )}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    style={{ paddingLeft: "12px", paddingRight: "12px" }}
                    variant="scrollable"
                    scrollButtons="on"
                >

                    {voos.map((item, index) => (
                        <Tab
                            label={item[0][0].departure + " - " + item[0][0].arrival}
                            {...a11yProps(index)}
                            className={classes.tab}
                        />
                    ))}
                    <Tab
                        label="Voos Selecionados"
                        {...a11yProps(voos.length)}
                        className={classes.tab}
                    />
                </Tabs>

            </AppBar>
            {fullscreen ? (
                <div>
                    {voos.map((voo, i) => (
                        <TabPanel value={value} index={i}>
                            <UFloatButton
                                actions={actionsServicos}
                                open={servicos}
                                setOpen={setServicos}
                            />
                            <Box
                                // marginTop={"35px"}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingBottom: "10px",
                                    flexDirection: "column",
                                }}
                            >
                                <CountDownTimer
                                    setResponseTimer={setOpenDialogWarning}
                                    timeInSeconds={1800}
                                    colorTypography={"#c62828"}
                                />
                                <Pagination
                                    size="small"
                                    count={countPage[i]}
                                    onChange={(e, value) => handleChangePagination(value, i)}
                                    shape="rounded"
                                    variant="outlined"
                                />
                            </Box>
                            <Grid container item xs={12} className={classes.UTabPanel}>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                >
                                    {voo.map((item) => (
                                        <>
                                            <Grid
                                                container
                                                style={{
                                                    marginBottom: "15px",
                                                    marginLeft: "-10px",
                                                    marginRight: "-10px",
                                                    minWidth: "-webkit-fill-available",
                                                    boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.19)"
                                                }}
                                                item
                                                xs={12}
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    xs={12}
                                                >
                                                    <Grid item xs={12}>
                                                        <Grid>
                                                            <Grid item xs={12} style={{ padding: "20px" }}>
                                                                <Grid container>
                                                                    {item[0].legs.map((leg, index) => (
                                                                        <>
                                                                            <Grid item xs={12} style={{ textAlign: "left", marginBottom: "13px" }} container>
                                                                                <Grid item xs={6} style={{ textAlign: "left" }}>
                                                                                    <Typography style={{ color: "#555555" }}>{leg.managedBy.iata} - {leg.flightNumber}</Typography>
                                                                                </Grid>
                                                                                {index == 0 ? (
                                                                                    <Grid item xs={6} style={{ textAlign: "right", margin: "-10px", justifyContent: "right", marginLeft: "10px" }} container>
                                                                                        <Typography style={{ color: "#c62828", fontSize: "15px", fontWeight: "600", marginTop: "12px" }}>
                                                                                            COTAR
                                                                                        </Typography>
                                                                                        <UCheckbox
                                                                                            color="primary"
                                                                                            checked={item[index]?.checkbox}
                                                                                            onChange={(e) =>
                                                                                                handleChangeCheckBox(e, item, i)
                                                                                            }
                                                                                        />
                                                                                    </Grid>
                                                                                ) : (null)}
                                                                            </Grid>
                                                                            <Grid item xs={12} container>
                                                                                <Grid item xs={4}>
                                                                                    <Typography style={{ color: "#111111", fontSize: "20px", fontWeight: "600" }}>
                                                                                        {new Date(leg.departureDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                                                    </Typography>
                                                                                    <Typography style={{ color: "#555555", fontSize: "12px" }}>
                                                                                        {`${leg.departure}`} - {new Date(leg.departureDate).toLocaleDateString([], { year: 'numeric', month: '2-digit', day: '2-digit' })}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4} style={{ textAlign: "center", marginTop: "6px" }}>
                                                                                    <Typography style={{ color: "#555555", fontSize: "12px" }}>{`${Math.floor(leg.duration / 60)} h ${leg.duration % 60} min`}</Typography>
                                                                                    <hr style={{ width: "100%", backgroundColor: "#ECECEC", height: "1px", border: "none", marginTop: "0px", marginBottom: "0px" }}></hr>
                                                                                    <Typography style={{ color: "#555555", fontSize: "12px" }}>
                                                                                        {item[0].escalas[0] == 0 ? "Direto" : "Parada"}
                                                                                    </Typography>
                                                                                </Grid>
                                                                                <Grid item xs={4} style={{ textAlign: "right" }}>
                                                                                    <Typography style={{ color: "#111111", fontSize: "20px", fontWeight: "600" }}>
                                                                                        {new Date(leg.arrivalDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                                                                    </Typography>
                                                                                    <Typography style={{ color: "#555555", fontSize: "12px" }}>
                                                                                        {`${leg.arrival}`} - {`${new Date(leg.arrivalDate).toLocaleDateString([], { year: 'numeric', month: '2-digit', day: '2-digit' })}`}
                                                                                    </Typography>
                                                                                    <Grid item xs={12} style={{ justifyContent: "right" }} container>
                                                                                        <img src={IconsSource.bagage} style={{ width: "20px" }}></img>
                                                                                        <img src={IconsSource.backpack} style={{ width: "20px" }}></img>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <Grid item xs={12} container>
                                                                                    <>
                                                                                        {item[0].legs.length != 1 ? (
                                                                                            index == item[0].legs.length - 1 ? (
                                                                                                null
                                                                                            ) : (<Grid item xs={12} style={{ textAlign: "center" }}>
                                                                                                <img
                                                                                                    key={index}
                                                                                                    src={EnumCompany.getLogoCompanhiaAereaEnum(leg.managedBy.iata).url_low}
                                                                                                    style={{
                                                                                                        height: "21px",
                                                                                                        width: "65px",
                                                                                                    }}
                                                                                                    alt="Logo da Companhia Aérea"
                                                                                                />
                                                                                            </Grid>)
                                                                                        ) : (
                                                                                            null
                                                                                        )
                                                                                        }
                                                                                    </>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </>
                                                                    ))}
                                                                    <Grid item xs={12} container style={{ marginTop: "25px" }}>
                                                                        <Grid item xs={6} style={{ textAlign: "center", justifyContent: "center" }}>
                                                                            <>
                                                                                {
                                                                                    item[0].legs.slice(-1).map((leg, index) => (
                                                                                        <img
                                                                                            key={index} // Certifique-se de adicionar uma chave única se você estiver mapeando em um componente React
                                                                                            src={EnumCompany.getLogoCompanhiaAereaEnum(leg.managedBy.iata).url_low}
                                                                                            style={{
                                                                                                height: "57px",
                                                                                                width: "147px",
                                                                                            }}
                                                                                        />
                                                                                    ))
                                                                                }
                                                                            </>
                                                                        </Grid>
                                                                        <Grid item xs={6} style={{ textAlign: "right" }}>
                                                                            <Typography style={{ color: "#555555", fontSize: "12px" }}>{`Sem Bagagem: ${getSemBagagem(item) <= 0
                                                                                ? "Não Possui"
                                                                                : "R$" + getSemBagagem(item).toFormatDefault()
                                                                                }`}</Typography>
                                                                            <Typography style={{ color: "#555555", fontSize: "12px" }}>{`Com Bagagem: ${getComBagagem(item) <= 0
                                                                                ? "Não Possui"
                                                                                : "R$" + getComBagagem(item).toFormatDefault()
                                                                                }`}</Typography>
                                                                            <Button
                                                                                size="small"
                                                                                fullWidth
                                                                                onClick={() => handleClickSelecionarTrecho(item, i)}
                                                                                style={{
                                                                                    color: "white",
                                                                                    background: "#c62828",
                                                                                    margin: "5px",
                                                                                }}
                                                                            >
                                                                                Selecionar Voo
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid >
                                                            </Grid>
                                                        </Grid >
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <hr style={{ width: "100%", backgroundColor: "#ECECEC", height: "1px", border: "none", marginTop: "0px", marginBottom: "0px" }}></hr>
                                        </>
                                    ))}
                                </Grid >
                            </Grid>
                        </TabPanel>
                    ))}
                    <TabPanel value={value} index={voos.length}>
                        <UFloatButton
                            actions={actionsServicos}
                            open={servicos}
                            setOpen={setServicos}
                        />
                        <Grid
                            container
                            item
                            xs={12}
                            style={{
                                marginBottom: theme.spacing(3),
                            }}
                        >
                            {trechosSelecionado.map((item, index) => (
                                <>
                                    <Grid
                                        container
                                        style={{
                                            marginLeft: "-10px",
                                            marginRight: "-10px",
                                            minWidth: "-webkit-fill-available",
                                        }}
                                        item
                                        xs={12}
                                    >
                                        <Grid
                                            style={{
                                                marginBottom: "15px",
                                                marginLeft: "-10px",
                                                marginRight: "-10px",
                                                minWidth: "-webkit-fill-available",
                                                boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.19)",
                                                padding: "15px"
                                            }}
                                            container
                                        >

                                            {item[0].legs.map((leg, i) => (
                                                <Grid item xs={12} container>
                                                    <Grid item xs={12} container>
                                                        <Grid item xs={8} style={{ marginTop: "10px" }}>
                                                            <Typography style={{ fontWeight: "600", color: "#434343", fontSize: "15px" }}>{leg.managedBy.iata} - {leg.flightNumber}</Typography>
                                                        </Grid>
                                                        <Grid item xs={4} style={{ textAlign: "right", margin: "-10px", marginLeft: "10px" }}>
                                                            {i == 0 ? (
                                                                <Typography style={{ fontSize: 12 }}>
                                                                    <IconButton
                                                                        onClick={() => handleClickAlterarTrecho(index)}
                                                                        style={{ color: "#c62828" }}
                                                                    >
                                                                        <SwapVertOutlined />
                                                                    </IconButton>
                                                                </Typography>
                                                            ) : (
                                                                null
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} container style={{ textAlign: "center" }}>
                                                        {i == 0 && item[0].escalas.length == 1 ? (
                                                            <>
                                                                {trechosSelecionado.length == 1 ? (
                                                                    null
                                                                ) : (
                                                                    <Grid item xs={12} container style={{ textAlign: "center", justifyContent: "center" }}>
                                                                        {index == 0 ? (
                                                                            <Typography style={{ fontWeight: "600", color: "#434343", fontSize: "15px" }}>IDA</Typography>
                                                                        ) : (
                                                                            <Typography style={{ fontWeight: "600", color: "#434343", fontSize: "15px" }}>VOLTA</Typography>
                                                                        )}
                                                                    </Grid>
                                                                )}
                                                            </>
                                                        ) : (
                                                            null
                                                        )}

                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <img
                                                            width={80}
                                                            height={80}
                                                            src={
                                                                EnumCompany.getLogoCompanhiaAereaEnum(
                                                                    leg.managedBy.iata
                                                                ).url_low
                                                            }
                                                            style={{
                                                                margin: "5px",
                                                                marginLeft: "-5px",
                                                                marginTop: "13px",
                                                                height: "40px",
                                                                width: "100px",

                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={6} style={{ padding: "23px", textAlign: "right" }}>
                                                        <Typography style={{ fontSize: "15px", marginTop: "1px", color: "#434343", fontWeight: "600" }}>{`${Math.floor(leg.duration / 60)} h ${leg.duration % 60} min`}</Typography>
                                                    </Grid>
                                                    <Grid item xs={12} container>
                                                        <>
                                                            <Grid item xs={4}>
                                                                <Typography style={{ color: "#434343", fontSize: "16px", fontWeight: "600" }}>
                                                                    {`${leg.departure}`}
                                                                </Typography>
                                                                <Typography style={{ color: "#555555", fontSize: "12px" }}>
                                                                    {`${new Date(leg.departureDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`} - {`${new Date(leg.departureDate).toLocaleDateString([], { year: 'numeric', month: '2-digit', day: '2-digit' })}`}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={4} style={{
                                                                textAlign: "center",
                                                                marginTop: "6px",
                                                                overflow: "hidden", // Para garantir que o ícone não ultrapasse o contêiner
                                                                position: "relative" // Para permitir posicionamento absoluto do ícone
                                                            }}>
                                                                <style>
                                                                    {`
                                                                        @keyframes moveRight {
                                                                            0% {
                                                                              transform: translateX(-40%); // Começa fora à esquerda
                                                                            }
                                                                            100% {
                                                                              transform: translateX(40%); // Termina fora à direita
                                                                            }
                                                                          }
                                                                    }`}
                                                                </style>
                                                                <div style={{
                                                                    width: "auto",
                                                                    animation: "moveRight 4s linear infinite", // Ajuste a duração conforme necessário
                                                                }}>
                                                                    <FlightIcon style={{ transform: "rotate(90deg)", color: "#c62828" }} />
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={4} style={{ textAlign: "right" }}>
                                                                <Typography style={{ color: "#434343", fontSize: "16px", fontWeight: "600" }}>
                                                                    {`${leg.arrival}`}
                                                                </Typography>
                                                                <Typography style={{ color: "#555555", fontSize: "12px" }}>
                                                                    {new Date(leg.arrivalDate).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {new Date(leg.arrivalDate).toLocaleDateString([], { year: 'numeric', month: '2-digit', day: '2-digit' })}
                                                                </Typography>
                                                                <Grid item xs={12} style={{ justifyContent: "right" }} container>
                                                                    <img src={IconsSource.bagage} style={{ width: "20px" }}></img>
                                                                    <img src={IconsSource.backpack} style={{ width: "20px" }}></img>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} style={{ marginTop: "20px" }}>
                                                                <hr style={{ width: "100%", backgroundColor: "#ECECEC", height: "1px", border: "none", marginTop: "0px", marginBottom: "0px" }}></hr>
                                                            </Grid>
                                                        </>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Grid item xs={12} style={{ marginTop: "10px" }}>
                                                <Badge
                                                    badgeContent={<AutoAwesome style={{ color: "#c62828", marginLeft: "-60px" }} />}
                                                    anchorOrigin={{
                                                        vertical: "top",
                                                        horizontal: "left",
                                                    }}
                                                    overlap="circular"
                                                >
                                                    <USelect
                                                        key={value === voos.length ? true : false}
                                                        {...{
                                                            itens: bagagens[index],
                                                            value: bagagem[index],
                                                            valueArray: bagagem,
                                                            setValueArray: setBagagem,
                                                            index: index,
                                                            label: "Tipo Bagagem",
                                                        }}
                                                    />
                                                </Badge>
                                            </Grid>
                                            <Grid item xs={12} container>
                                                <Grid item xs={8}>
                                                    <Typography style={{ fontWeight: "600", color: "#434343", fontSize: "15px" }}>Total</Typography>
                                                </Grid>
                                                <Grid item xs={4} style={{ textAlign: "right" }}>
                                                    <Typography style={{ fontSize: 12 }}>
                                                        {`R$ ${bagagens[index][bagagem[index]]?.preco.toFormatDefault() || ""}`}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {/* <Grid item xs={12}>
                                            {item[0].legs.map((leg) => (
                                                <Grid container>
                                                    <Grid item xs={12} className={classes.gridLogo}>
                                                        <Tooltip
                                                            title={
                                                                <Typography style={{ fontSize: "10pt" }}>
                                                                    {leg.managedBy.name}
                                                                </Typography>
                                                            }
                                                        >
                                                            <img
                                                                width={80}
                                                                height={80}
                                                                src={
                                                                    EnumCompany.getLogoCompanhiaAereaEnum(
                                                                        leg.managedBy.iata
                                                                    ).url_low
                                                                }
                                                                style={{
                                                                    margin: "5px",
                                                                    height: "30px",
                                                                    width: "60px",
                                                                }}
                                                            />
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={6}>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={2}
                                                                        className={classes.gridIcon}
                                                                    >
                                                                        <FlightTakeoffOutlined />
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        <Grid
                                                                            container
                                                                            className={classes.gridLegs}
                                                                        >
                                                                            <Grid item xs={12}>
                                                                                <Typography
                                                                                    style={{ fontSize: 12 }}
                                                                                >{`${leg.departure}`}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography
                                                                                    style={{ fontSize: 12 }}
                                                                                >{`${new Date(leg.departureDate)
                                                                                    .toLocaleString()
                                                                                    .replace(":00", "")}`}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Grid container>
                                                                    <Grid
                                                                        item
                                                                        xs={2}
                                                                        className={classes.gridIcon}
                                                                    >
                                                                        <FlightLandOutlined />
                                                                    </Grid>
                                                                    <Grid item xs={10}>
                                                                        <Grid
                                                                            container
                                                                            className={classes.gridLegs}
                                                                        >
                                                                            <Grid item xs={12}>
                                                                                <Typography
                                                                                    style={{ fontSize: 12 }}
                                                                                >{`${leg.arrival}`}</Typography>
                                                                            </Grid>
                                                                            <Grid item xs={12}>
                                                                                <Typography
                                                                                    style={{ fontSize: 12 }}
                                                                                >{`${new Date(leg.arrivalDate)
                                                                                    .toLocaleString()
                                                                                    .replace(":00", "")}`}</Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </Grid> */}
                                        {/* <Grid item xs={12} className={classes.gridBagagem}>
                                            <Accordion
                                                style={{
                                                    width: "100%",
                                                    boxShadow: "none",
                                                    background: "whitesmoke",
                                                }}
                                            >
                                                <AccordionSummary expandIcon={<ExpandMoreOutlined />}>
                                                    <Typography style={{ fontSize: 12 }}>
                                                        Mais informações ss
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container>
                                                        <Grid item xs={6}>
                                                            {item[0].escalas.map((stops) => (
                                                                <Typography style={{ fontSize: 12 }}>
                                                                    Número de paradas: {stops}
                                                                </Typography>
                                                            ))}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {item[0].duracao.map((time) => (
                                                                <>
                                                                    <Typography className={classes.gridTypo}>
                                                                        Duração: {time}
                                                                    </Typography>
                                                                </>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid> */}
                                        {/* <Grid item xs={12} className={classes.gridBagagem}>
                                            <Grid container>
                                                <Grid item xs={10}>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography className={classes.gridTypo}>
                                                                <WorkOffOutlined fontSize="small" />
                                                                {`Sem Bagagem: ${getSemBagagem(item) <= 0
                                                                    ? "Não Possui"
                                                                    : "R$" +
                                                                    getSemBagagem(item).toFormatDefault()
                                                                    }`}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography className={classes.gridTypo}>
                                                                <WorkOutlineOutlined fontSize="small" />
                                                                {`Com Bagagem: ${getComBagagem(item) <= 0
                                                                    ? "Não Possui"
                                                                    : "R$" +
                                                                    getComBagagem(item).toFormatDefault()
                                                                    }`}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography style={{ fontSize: 12 }}>
                                                        <IconButton
                                                            onClick={() => handleClickAlterarTrecho(index)}
                                                            style={{ color: "#c62828" }}
                                                        >
                                                            <SwapVertOutlined />
                                                        </IconButton>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid> */}
                                        {/* <Grid item xs={12}>
                                            <USelect
                                                key={value === voos.length ? true : false}
                                                {...{
                                                    itens: bagagens[index],
                                                    value: bagagem[index],
                                                    valueArray: bagagem,
                                                    setValueArray: setBagagem,
                                                    index: index,
                                                    label: "Tipo Bagagem",
                                                }}
                                            />
                                            <Divider style={{ marginTop: "10px" }} />
                                        </Grid> */}
                                    </Grid>
                                </>
                            ))}

                            <Grid
                                container
                                item
                                xs={12}
                                style={{
                                    marginTop: theme.spacing(2),
                                    padding: theme.spacing(1),
                                }}
                            >
                                <Grid item xs={12}>
                                    <Button
                                        disabled={isLoading}
                                        fullWidth
                                        className={classes.button}
                                        onClick={onClickOpenReservarModal}
                                    >
                                        Tarifar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid >
                    </TabPanel >
                </div>
            ) : (
                <div>
                    {voos.map((voo, i) => (
                        <TabPanel value={value} index={i}>
                            <Box
                                position={"fixed"}
                                style={{ height: "-webkit-fill-available", width: "91vw" }}
                            >
                                <UFloatButton
                                    actions={actionsServicos}
                                    open={servicos}
                                    setOpen={setServicos}
                                    style={{ padding: "0px" }}
                                />
                                <Grid item container xs={12} className={classes.UTabPanel}>
                                    <Grid
                                        item
                                        xs={12}
                                        className={classes.tableRoot}
                                        style={{
                                            padding: '0px'
                                            // paddingLeft: "0px",
                                            // paddingRight: "0px",
                                            // paddingBottom: "0px",
                                        }}
                                    >
                                        <Paper elevation={5}>
                                            <TableContainer style={{ maxHeight: "71vh" }}>
                                                <Table
                                                    stickyHeader
                                                    ref={tableRef}
                                                    style={{ paddingTop: "16px" }}
                                                >
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell
                                                                style={{ padding: "8px" }}
                                                                align="center"
                                                            ></TableCell>
                                                            <TableCell
                                                                style={{ padding: "8px" }}
                                                                align="center"
                                                            >
                                                                CIA
                                                            </TableCell>
                                                            <TableCell
                                                                style={{ padding: "8px", cursor: "pointer" }}
                                                                align="center"
                                                                onClick={() => ordenarTabela("flightNumber", 0)}
                                                            >
                                                                <div className={classes.header}>
                                                                    {headers[0].label}
                                                                    {headers[0].icon}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                style={{ padding: "8px", cursor: "pointer" }}
                                                                align="center"
                                                                onClick={() =>
                                                                    ordenarTabela("departureDate", 1)
                                                                }
                                                            >
                                                                <div className={classes.header}>
                                                                    {headers[1].label}
                                                                    {headers[1].icon}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                style={{ padding: "8px", cursor: "pointer" }}
                                                                align="center"
                                                                onClick={() => ordenarTabela("arrivalDate", 2)}
                                                            >
                                                                <div className={classes.header}>
                                                                    {headers[2].label}
                                                                    {headers[2].icon}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                style={{ padding: "8px" }}
                                                                align="center"
                                                            >
                                                                Origem
                                                            </TableCell>
                                                            <TableCell
                                                                style={{ padding: "8px" }}
                                                                align="center"
                                                            >
                                                                Destino
                                                            </TableCell>
                                                            <TableCell
                                                                style={{ padding: "8px" }}
                                                                align="center"
                                                            >
                                                                ESC.
                                                            </TableCell>
                                                            <TableCell
                                                                style={{ padding: "8px" }}
                                                                align="center"
                                                            >
                                                                Tempo Total
                                                            </TableCell>
                                                            <TableCell
                                                                style={{ padding: "8px", cursor: "pointer" }}
                                                                align="center"
                                                                onClick={() => ordenarTabela("sembagagem", 3)}
                                                            >
                                                                <div className={classes.header}>
                                                                    {headers[3].label}
                                                                    {headers[3].icon}
                                                                </div>
                                                            </TableCell>
                                                            <TableCell
                                                                style={{ padding: "8px", cursor: "pointer" }}
                                                                align="center"
                                                                onClick={() => ordenarTabela("combagagem", 4)}
                                                            >
                                                                <div className={classes.header}>
                                                                    {headers[4].label}
                                                                    {headers[4].icon}
                                                                </div>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {ordenarLista(i, orderBy, order).map(
                                                            (item, index) => (
                                                                <TableRow>
                                                                    <TableCell
                                                                        style={{ padding: "8px" }}
                                                                        align="center"
                                                                        padding=""
                                                                    >
                                                                        <Tooltip
                                                                            title={
                                                                                "Clique aqui para adicionar voo aos selecionados."
                                                                            }
                                                                        >
                                                                            <IconButton
                                                                                onClick={() =>
                                                                                    handleClickSelecionarTrecho(item, i)
                                                                                }
                                                                                style={{ color: "#c62828" }}
                                                                            >
                                                                                <AddCircleOutline />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip
                                                                            title={
                                                                                "Marque para adicionar a lista de cotação."
                                                                            }
                                                                        >
                                                                            <UCheckbox
                                                                                color="primary"
                                                                                checked={item[0].checkbox}
                                                                                onChange={(e) =>
                                                                                    handleChangeCheckBox(e, item, i)
                                                                                }
                                                                            />
                                                                        </Tooltip>
                                                                        {/* <h1>{functTeste(item, i)}</h1> */}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ padding: "8px" }}
                                                                        align="center"
                                                                    >
                                                                        {variadosReduxTipoPesquisa.tipoPesquisaAct ==
                                                                            "nacional" ? (
                                                                            <Tooltip
                                                                                title={item[0].legs[0].managedBy.name}
                                                                            >
                                                                                <img
                                                                                    width={80}
                                                                                    height={80}
                                                                                    src={
                                                                                        EnumCompany.getLogoCompanhiaAereaEnum(
                                                                                            item[0].legs[0].managedBy.iata
                                                                                        ).logo
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        ) : (
                                                                            item[0].legs.map((leg) => (
                                                                                <Box
                                                                                    marginY={"3px"}
                                                                                    height={"24px"}
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                    }}
                                                                                >
                                                                                    <Tooltip
                                                                                        title={
                                                                                            <Typography
                                                                                                style={{ fontSize: "10pt" }}
                                                                                            >
                                                                                                {leg.managedBy.name}
                                                                                            </Typography>
                                                                                        }
                                                                                    >
                                                                                        <Typography
                                                                                            style={{
                                                                                                display: "flex",
                                                                                                alignContent: "center",
                                                                                                justifyContent: "center",
                                                                                                alignItems: "center",
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                width={51}
                                                                                                height={17}
                                                                                                src={
                                                                                                    EnumCompany.getLogoCompanhiaAereaEnum(
                                                                                                        leg.managedBy.iata
                                                                                                    ).url_low
                                                                                                }
                                                                                            />
                                                                                        </Typography>
                                                                                    </Tooltip>
                                                                                </Box>
                                                                            ))
                                                                        )}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ padding: "8px" }}
                                                                        align="center"
                                                                    >
                                                                        {item[0].legs.map((leg) => (
                                                                            <>
                                                                                <Box
                                                                                    marginY={"3px"}
                                                                                    height={"24px"}
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                    }}
                                                                                >
                                                                                    <Typography style={{ fontSize: 12 }}>
                                                                                        {leg.flightNumber}
                                                                                    </Typography>
                                                                                    {leg.operatedBy.iata == leg.managedBy.iata ? (
                                                                                        null
                                                                                    ) : (
                                                                                        <Tooltip title={`O voo ${leg.flightNumber} está sendo operado pela cia ${EnumCompany.getLogoCompanhiaAereaEnum(leg.operatedBy.iata).nome} (${leg.operatedBy.iata}).`}>
                                                                                            <IconButton style={{ backgroundColor: 'transparent' }}>
                                                                                                <InfoOutlined />
                                                                                            </IconButton>
                                                                                        </Tooltip>
                                                                                    )}
                                                                                </Box>
                                                                            </>
                                                                        ))}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ padding: "8px" }}
                                                                        align="center"
                                                                    >
                                                                        {item[0].legs.map((leg) => (
                                                                            <>
                                                                                <Box
                                                                                    marginY={"3px"}
                                                                                    height={"24px"}
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                    }}
                                                                                >
                                                                                    <Typography style={{ fontSize: 12 }}>
                                                                                        {new Date(
                                                                                            leg.departureDate
                                                                                        ).toLocaleString()}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </>
                                                                        ))}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ padding: "8px" }}
                                                                        align="center"
                                                                    >
                                                                        {item[0].legs.map((leg) => (
                                                                            <>
                                                                                <Box
                                                                                    marginY={"3px"}
                                                                                    height={"24px"}
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                    }}
                                                                                >
                                                                                    <Typography style={{ fontSize: 12 }}>
                                                                                        {new Date(
                                                                                            leg.arrivalDate
                                                                                        ).toLocaleString()}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </>
                                                                        ))}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ padding: "8px" }}
                                                                        align="center"
                                                                    >
                                                                        {item[0].legs.map((leg) => (
                                                                            <>
                                                                                <Box
                                                                                    margin={"3px"}
                                                                                    height={"24px"}
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                    }}
                                                                                >
                                                                                    <Typography style={{ fontSize: 12 }}>
                                                                                        {leg.departure}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </>
                                                                        ))}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ padding: "8px" }}
                                                                        align="center"
                                                                    >
                                                                        {item[0].legs.map((leg) => (
                                                                            <>
                                                                                <Box
                                                                                    marginY={"3px"}
                                                                                    height={"24px"}
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        alignItems: "center",
                                                                                        justifyContent: "center",
                                                                                    }}
                                                                                >
                                                                                    <Typography style={{ fontSize: 12 }}>
                                                                                        {leg.arrival}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </>
                                                                        ))}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ padding: "8px" }}
                                                                        align="center"
                                                                    >
                                                                        {item[0].escalas.map((stops) => (
                                                                            <Typography style={{ fontSize: 12 }}>
                                                                                {stops}
                                                                            </Typography>
                                                                        ))}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ padding: "8px" }}
                                                                        align="center"
                                                                    >
                                                                        {item[0].duracao.map((time) => (
                                                                            <Typography style={{ fontSize: 12 }}>
                                                                                {time}
                                                                            </Typography>
                                                                        ))}
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ padding: "8px" }}
                                                                        align="center"
                                                                    >
                                                                        <Typography>
                                                                            {`${getSemBagagem(item) <= 0
                                                                                ? "Não Possui"
                                                                                : "R$" +
                                                                                getSemBagagem(
                                                                                    item
                                                                                ).toFormatDefault()
                                                                                }`}
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell
                                                                        style={{ padding: "8px" }}
                                                                        align="center"
                                                                    >
                                                                        <Typography>
                                                                            {`${getComBagagem(item) <= 0
                                                                                ? "Não Possui"
                                                                                : "R$" +
                                                                                getComBagagem(
                                                                                    item
                                                                                ).toFormatDefault()
                                                                                }`}
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Box
                                                marginY={"10px"}
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <Grid container>
                                                    <Grid item xs={2}></Grid>
                                                    <Grid
                                                        item
                                                        xs={8}
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        { /* Localização */}
                                                        <Pagination
                                                            count={countPage[i]}
                                                            onChange={(e, value) =>
                                                                handleChangePagination(value, i)
                                                            }
                                                            shape="rounded"
                                                            variant="outlined"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Box
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "left",
                                                            }}
                                                        >
                                                            <Typography style={{ fontSize: "12px" }}>
                                                                Total de vôos encontrados {subList.length}
                                                            </Typography>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Box>
                        </TabPanel>
                    ))}
                    <TabPanel value={value} index={voos.length}>
                        <UFloatButton
                            actions={actionsServicos}
                            open={servicos}
                            setOpen={setServicos}
                        />
                        <Grid item container xs={12} className={classes.UTabPanel}>
                            <Grid item xs={12} className={classes.tableRoot}>
                                <Paper elevation={5}>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center"></TableCell>
                                                    <TableCell align="center">CIA</TableCell>
                                                    <TableCell align="center">Voo</TableCell>
                                                    <TableCell align="center">Saída</TableCell>
                                                    <TableCell align="center">Chegada</TableCell>
                                                    <TableCell align="center">Origem</TableCell>
                                                    <TableCell align="center">Destino</TableCell>
                                                    <TableCell align="center">ESC.</TableCell>
                                                    <TableCell align="center">Tempo Total</TableCell>
                                                    <TableCell align="center">Bagagem</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {trechosSelecionado.map((item, index) => (
                                                    <TableRow>
                                                        <TableCell
                                                            align="center"
                                                            padding=""
                                                            style={{ padding: "8px" }}
                                                        >
                                                            <Tooltip title={"Clique para alterar esse voo."}>
                                                                <IconButton
                                                                    onClick={() =>
                                                                        handleClickAlterarTrecho(index)
                                                                    }
                                                                    style={{ color: "#c62828" }}
                                                                >
                                                                    <SwapVertOutlined />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Box
                                                                style={{
                                                                    height: "100%",
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                {variadosReduxTipoPesquisa.tipoPesquisaAct ==
                                                                    "nacional" ? (
                                                                    item !== undefined ? (
                                                                        <>
                                                                            <Tooltip
                                                                                title={
                                                                                    item[0]?.legs[0]?.managedBy?.name
                                                                                }
                                                                            >
                                                                                <img
                                                                                    width={80}
                                                                                    height={80}
                                                                                    src={
                                                                                        EnumCompany.getLogoCompanhiaAereaEnum(
                                                                                            item[0]?.legs[0]?.managedBy?.iata
                                                                                        ).logo
                                                                                    }
                                                                                />
                                                                            </Tooltip>
                                                                        </>
                                                                    ) : null
                                                                ) : (
                                                                    item[0]?.legs?.map((leg) => (
                                                                        <Box
                                                                            marginY={"3px"}
                                                                            height={"24px"}
                                                                            style={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                justifyContent: "center",
                                                                            }}
                                                                        >
                                                                            <Tooltip
                                                                                title={
                                                                                    <Typography
                                                                                        style={{ fontSize: "10pt" }}
                                                                                    >
                                                                                        {leg.managedBy.name}
                                                                                    </Typography>
                                                                                }
                                                                            >
                                                                                <img
                                                                                    width={80}
                                                                                    height={80}
                                                                                    src={
                                                                                        EnumCompany.getLogoCompanhiaAereaEnum(
                                                                                            leg.managedBy.iata
                                                                                        ).url_low
                                                                                    }
                                                                                    style={{
                                                                                        margin: "5px",
                                                                                        height: "12px",
                                                                                        width: "60px",
                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                        </Box>
                                                                    ))
                                                                )}
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {item[0].legs.map((leg) => (
                                                                <>
                                                                    <Box
                                                                        marginY={"3px"}
                                                                        height={"24px"}
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Typography style={{ fontSize: 12 }}>
                                                                            {leg.flightNumber}
                                                                        </Typography>
                                                                    </Box>
                                                                </>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {item[0].legs.map((leg) => (
                                                                <>
                                                                    <Box
                                                                        marginY={"3px"}
                                                                        height={"24px"}
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Typography style={{ fontSize: 12 }}>
                                                                            {new Date(
                                                                                leg.departureDate
                                                                            ).toLocaleString()}
                                                                        </Typography>
                                                                    </Box>
                                                                </>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {item[0].legs.map((leg) => (
                                                                <>
                                                                    <Box
                                                                        marginY={"3px"}
                                                                        height={"24px"}
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Typography style={{ fontSize: 12 }}>
                                                                            {new Date(
                                                                                leg.arrivalDate
                                                                            ).toLocaleString()}
                                                                        </Typography>
                                                                    </Box>
                                                                </>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {item[0].legs.map((leg) => (
                                                                <>
                                                                    <Box
                                                                        marginY={"3px"}
                                                                        height={"24px"}
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Typography style={{ fontSize: 12 }}>
                                                                            {leg.departure}
                                                                        </Typography>
                                                                    </Box>
                                                                </>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {item[0].legs.map((leg) => (
                                                                <>
                                                                    <Box
                                                                        marginY={"3px"}
                                                                        height={"24px"}
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Typography style={{ fontSize: 12 }}>
                                                                            {leg.arrival}
                                                                        </Typography>
                                                                    </Box>
                                                                </>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Typography style={{ fontSize: 12 }}>
                                                                {item[0].numberOfStops}
                                                            </Typography>
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {item[0].duracao.map((time) => (
                                                                <Typography style={{ fontSize: 12 }}>
                                                                    {time}
                                                                </Typography>
                                                            ))}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            <Box width={"47vh"}>
                                                                <Badge
                                                                    badgeContent={<AutoAwesome style={{ color: "#c62828", marginLeft: "-60px" }} />}
                                                                    anchorOrigin={{
                                                                        vertical: "top",
                                                                        horizontal: "left",
                                                                    }}
                                                                    overlap="circular"
                                                                >
                                                                    <USelect
                                                                        key={value === voos.length ? true : false}
                                                                        {...{
                                                                            itens: bagagens[index],
                                                                            value: bagagem[index],
                                                                            valueArray: bagagem,
                                                                            quantidadeBagagem: bagagens[index],
                                                                            setValueArray: setBagagem,
                                                                            index: index,
                                                                            label: "Tipo Bagagem",
                                                                        }}
                                                                    />
                                                                </Badge>
                                                            </Box>
                                                            <Grid
                                                                container
                                                                item
                                                                xs={12}
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "space-between",
                                                                    alignItems: "center",
                                                                }}
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                    style={{
                                                                        marginTop: theme.spacing(1),
                                                                        display: "flex",
                                                                        justifyContent: "flex-start",
                                                                    }}
                                                                >
                                                                    <Typography style={{ fontSize: 12 }}>
                                                                        Preço:
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={4}
                                                                    style={{
                                                                        marginTop: theme.spacing(1),
                                                                        display: "flex",
                                                                        justifyContent: "flex-end",
                                                                    }}
                                                                >
                                                                    <Typography style={{ fontSize: 12 }}>
                                                                        {`R$${bagagens[index][
                                                                            bagagem[index]
                                                                        ].preco.toFormatDefault() || ""
                                                                            }`}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                style={{
                                    padding: theme.spacing(2),
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <Button
                                    disabled={isLoading}
                                    className={classes.button}
                                    onClick={onClickOpenReservarModal}
                                >
                                    Tarifar
                                </Button>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </div>
            )}
        </Box>
    );
};

export default ListaAereoReservaFacil;
