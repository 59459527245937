import React from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";

export const ExportExcel = (props) => {
  const exportToExcel = async () => {
    const { columns, data, name } = props;

    // Certifique-se de que as colunas possuem `header` e `key`
    const formattedColumns = columns.map((col, index) => ({
      header: col, // Use o nome da coluna como o cabeçalho
      key: `col${index}`, // Identificador único para cada coluna
      width: 20, // Define uma largura padrão
    }));

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(name || "Relatório");

    // Define as colunas no Excel
    worksheet.columns = formattedColumns;

    // Adiciona os dados na planilha
    data.forEach((row) => {
      const formattedRow = formattedColumns.reduce((acc, col, colIndex) => {
        acc[col.key] = row[colIndex]; // Usa o índice para mapear os valores
        return acc;
      }, {});
      worksheet.addRow(formattedRow);
    });

    // Gera o arquivo Excel
    const buffer = await workbook.xlsx.writeBuffer();

    // Salva o arquivo usando file-saver
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, `Relatório - ${new Date().toLocaleString()}.xlsx`);
  };

  return (
    <button onClick={exportToExcel}>
      {props.element || "Exportar para Excel"}
    </button>
  );
};

export default ExportExcel;
